import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiGqlClient } from '~/libs/gql';
import { graphql } from '~/types/__generated/gql';
import {
	CreateCustomFieldMutation,
	CreateCustomFieldMutationVariables,
} from '~/types/__generated/gql/graphql';
import { getCustomFieldsQueryKey, GetCustomFieldsResults } from '../useGetCustomFields';

const query = /* GraphQL */ `
	mutation CreateCustomField($input: CreateCustomFieldInput!) {
		createCustomField(input: $input) {
			name
			type
			model
			company
			selectOptions {
				name
				_id
				createdAt
				updatedAt
			}
			_id
			createdAt
			updatedAt
		}
	}
`;

export type CreateCustomFieldVariables = CreateCustomFieldMutationVariables;
export type CreateCustomFieldResults = CreateCustomFieldMutation['createCustomField'];

export const useCreateCustomField = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (variables: CreateCustomFieldVariables) =>
			apiGqlClient.request(graphql(query), variables).then((res) => res.createCustomField),
		onSuccess: async (data) => {
			queryClient.setQueryData<GetCustomFieldsResults>(getCustomFieldsQueryKey(), (oldData) =>
				oldData ? [...oldData, data] : [data]
			);
		},
	});
};
