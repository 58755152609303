import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiGqlClient } from '~/libs/gql';
import { graphql } from '~/types/__generated/gql';
import {
	UpdateCustomFieldMutation,
	UpdateCustomFieldMutationVariables,
} from '~/types/__generated/gql/graphql';
import { getCustomFieldsQueryKey, GetCustomFieldsResults } from '../useGetCustomFields';

const query = /* GraphQL */ `
	mutation UpdateCustomField($id: ObjectID!, $input: UpdateCustomFieldInput!) {
		updateCustomField(id: $id, input: $input) {
			name
			type
			model
			company
			selectOptions {
				name
				_id
				createdAt
				updatedAt
			}
			_id
			createdAt
			updatedAt
		}
	}
`;

export type UpdateCustomFieldVariables = UpdateCustomFieldMutationVariables;
export type UpdateCustomFieldResults = UpdateCustomFieldMutation['updateCustomField'];

export const useUpdateCustomField = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (variables: UpdateCustomFieldVariables) =>
			apiGqlClient.request(graphql(query), variables).then((res) => res.updateCustomField),
		onSuccess: async (data) => {
			queryClient.setQueryData<GetCustomFieldsResults>(getCustomFieldsQueryKey(), (oldData) =>
				oldData ? oldData.map((o) => (o._id === data._id ? data : o)) : [data]
			);
		},
	});
};
