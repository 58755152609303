import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiGqlClient } from '~/libs/gql';
import { graphql } from '~/types/__generated/gql';
import {
	DeleteCustomFieldMutation,
	DeleteCustomFieldMutationVariables,
} from '~/types/__generated/gql/graphql';
import { getCustomFieldsQueryKey, GetCustomFieldsResults } from '../useGetCustomFields';

const query = /* GraphQL */ `
	mutation DeleteCustomField($id: ObjectID!) {
		deleteCustomField(id: $id) {
			_id
		}
	}
`;

export type DeleteCustomFieldVariables = DeleteCustomFieldMutationVariables;
export type DeleteCustomFieldResults = DeleteCustomFieldMutation['deleteCustomField'];

export const useDeleteCustomField = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (variables: DeleteCustomFieldVariables) =>
			apiGqlClient.request(graphql(query), variables).then((res) => res.deleteCustomField),
		onSuccess: async (data) => {
			queryClient.setQueryData<GetCustomFieldsResults>(getCustomFieldsQueryKey(), (oldData) =>
				oldData ? oldData.filter((o) => o._id !== data._id) : []
			);
		},
	});
};
